import styles from './Floating.module.css'
import {useLocation, useNavigate} from "react-router-dom";
import ym from "react-yandex-metrika";

export const Floating = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return(
        <div className={styles.main} onClick={() => {
            ym('reachGoal', 'ModalFormOpened');
            navigate(location.pathname + '?modal');
        }}>
            <img src="/img/message.png"  alt="message"/>
        </div>
    )
}