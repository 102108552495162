import React, {useEffect, useState} from 'react';
import styles from './App.module.css';
import Header from "./components/header/Header";
import Content from "./components/content/Content";
import Footer from "./components/footer/Footer";
import SwiperProvider from "./contexts/SwiperContext/SwiperContext";
import Popup from "./components/popup/Popup";
import {Callback} from "./components/callback/Callback";
import {useLocation, useNavigate} from "react-router-dom";
import {Floating} from "./components/floating/Floating";
import {Alert} from "./components/alert/Alert";
import {IAlert} from "./models/IAlert";

function App() {
    const [popupDisplay, setPopupDisplay] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const [alert, setAlert] = useState<IAlert>({text: '', type: 'Success', display: false});

    useEffect(() => {
        let modalIndex = location.search.indexOf('modal');
        if (modalIndex > 0) {
            setPopupDisplay(true);
        }

    }, [location.search])

    const closeModal = () => {
        navigate(location.pathname);
        setPopupDisplay(false);
    }

  return (
      <SwiperProvider>
          <div className={styles.App}>
            <Header />
            <Content />
            <Footer />
              <Popup onDisplay={popupDisplay} onClose={closeModal}>
                  <Callback
                      onSuccess={() => {
                          setAlert({text: 'Заявка отправлена. Мы свяжемся с Вами в ближайшее время.', type: 'Success', display: true})
                          closeModal();
                      }}
                      onFail={() => {
                          setAlert({text: 'При отправке заявки возникла ошибка. Пожалуйста, свяжитесь с нами любым другим, доступным Вам способом.', type: 'Error', display: true})
                          closeModal();
                      }}
                      title={'Заполните поля чтобы наш специалист мог с Вами связаться.'} />
              </Popup>
              <Floating />
              <Alert text={alert.text} type={alert.type} display={alert.display} onClose={() => setAlert({...alert, display: false })} />
          </div>
      </SwiperProvider>
  );
}

export default App;
