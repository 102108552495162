import styles from './Footer.module.css';

export default function Footer(){
    return (
        <div className={styles.main}>
            <div className={styles.left}>

            </div>
            <div className={styles.center}>
                ОЮЦ "Фемида" 2024г.
            </div>
            <div className={styles.right}>

            </div>
        </div>
    )
}