import styles from './Popup.module.css'
import {IPopupProps} from "./IPopupProps";

export default function Popup({children, onDisplay, onClose}: IPopupProps) {
    return (
        <div className={styles.main}
             onClick={() => onClose()}>
            {
                onDisplay &&
                <div className={styles.layout}>
                    <div className={styles.block} onClick={(event) => event.stopPropagation()}>
                        {children}
                    </div>
                </div>
            }
        </div>
    )
}