import styles from './Callback.module.css'
import {ICallback} from "./ICallback";
import ReactInputMask from "react-input-mask";
import React, {useState} from "react";
import ym from "react-yandex-metrika";
import {Alert} from "../alert/Alert";
import {IAlert} from "../../models/IAlert";

export const Callback = ({title, onSuccess, onFail}:ICallback) => {
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [alert, setAlert] = useState<IAlert>({text: '', type: 'Success', display: false});

    return(
        <div className={styles.main}>
            <h3>{title}</h3>
            <div className={styles.row}>
                <label htmlFor={'name'}>Ваше имя:</label>
                <input
                    id={'name'}
                    type={'text'}
                    onChange={(event) => {setName(event.target.value)}}
                />
            </div>
            <div className={styles.row}>
                <label htmlFor={'phone'}>Телефон:</label>
                <ReactInputMask
                    id={'phone'}
                    type={'tel'}
                    mask={'+7 (999) 999-99-99'}
                    placeholder={'+7 (___) ___-__-__'}
                    onChange={(event) => {setPhone(event.target.value)}}
                ></ReactInputMask>
            </div>
            <div className={styles.buttons}>
                <div className={styles.confirmBlock}>
                    <input className={styles.confirm} type={'checkbox'} checked={isConfirmed} onChange={() => setIsConfirmed(!isConfirmed)} />
                    <label className={styles.confirmLabel} >Согласие на обработку персональных данных</label>
                </div>
                <button
                    className={isConfirmed ? '' : styles.disabled}
                    style={{cursor: isSent ? "wait" : "default"}}
                    disabled={!isConfirmed || isSent}
                    onClick={() => {
                        if(!name) {
                            setAlert({text: 'Имя пользователя не может быть пустым.', type: 'Error', display: true})
                            setIsSent(false);
                            return;
                        }

                        if(!phone) {
                            setAlert({text: 'Необходимо заполнить номер телефона.', type: 'Error', display: true})
                            setIsSent(false);
                            return;
                        }

                        if(phone.indexOf('_') >= 0) {
                            setAlert({text: 'Номер телефона указан некорректно.', type: 'Error', display: true})
                            setIsSent(false);
                            return;
                        }

                        let botBaseUrl = 'https://api.telegram.org/bot1772110124:AAEXkygpHgvEjVZyZrMPFXp618uY6o9CpIA/sendMessage?chat_id=-1001473844959&text=';
                        let message = 'Пользователь ' + name + ' просит перезвонить ему по номеру ' + phone + '.'

                        ym('reachGoal', 'SendCallback');

                        setIsSent(true);
                        fetch(botBaseUrl + message)
                            .then(() => {
                                if(onSuccess){
                                    onSuccess();
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                if(onFail){
                                    onFail();
                                }
                            })
                            .finally(() => {
                                setIsSent(false);
                            });
                    }}>
                    Отправить
                </button>
            </div>
            <Alert text={alert.text} type={alert.type} display={alert.display} onClose={() => setAlert({...alert, display: false })} />
        </div>
    )
}