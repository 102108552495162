import styles from './Alert.module.css'
import {IAlertProps} from "./IAlertProps";
import {useEffect} from "react";

export const Alert = ({text, type, display, onClose}:IAlertProps) => {

    let styleType;
    switch (type){
        case 'Warning':
            styleType = styles.warning;
            break;
        case 'Error':
            styleType = styles.error;
            break;
        default:
            styleType = styles.success;
            break;
    }

    useEffect(() => {
        if (display && type !== 'Error') {
            setTimeout(() => onClose(), 5000);
        }
    }, [display])

    return(
        <div className={[styles.main, display ? styles.show : ''].join(' ')} onClick={() => onClose()}>
            <div className={[styles.box, styleType, display ? styles.show : ''].join(' ')}>
                {text}
            </div>
        </div>
    )
}