import styles from './ProcedurePage.module.css'
import {useState} from "react";

export default function ProcedurePage() {
    const [activeStage, setActiveStage] = useState(1);

    return (
        <div id={'procedure-page'} className={[styles.main].join(' ')}>
            <h2>Процедура банкротства</h2>
            <div className={styles.stagesBlock}>
                <div className={[
                    styles.stage,
                    activeStage === 1 ? styles.active : ''
                ].join(' ')}>
                    <h3 onClick={() => setActiveStage(1)}>Этап 1</h3>
                    <ul>
                        <li>Бесплатная консультация;</li>
                        <li>Анализ ситуации и выявление возможности применения процедуры банкротства;</li>
                        <li>Определение рисков и выгоды данной процедуры для клиента.</li>
                    </ul>
                </div>
                <div className={[
                    styles.stage,
                    activeStage === 2 ? styles.active : ''
                ].join(' ')}>
                    <h3 onClick={() => setActiveStage(2)}>Этап 2</h3>
                    <ul>
                        <li>Заключение договора / оформление доверенности;</li>
                        <li>Сбор необходимых документов;</li>
                        <li>Составление искового заявления;</li>
                        <li>Уведомление кредиторов по почте о подаче заявления в суд;</li>
                        <li>Выбор финансового управляющего из членов СРО;</li>
                        <li>Внесение государственной пошлины (300 рублей);</li>
                        <li>Внесение на счет суда депозита за его услуги (25 000рублей);</li>
                        <li>Подача в Арбитражный суд заявления о признании банкротом.</li>
                    </ul>
                </div>
                <div className={[
                    styles.stage,
                    activeStage === 3 ? styles.active : ''
                ].join(' ')}>
                    <h3 onClick={() => setActiveStage(3)}>Этап 3</h3>
                    <ul>
                        <li>Заседание суда с вынесением решения о признании гражданина банкротом;</li>
                        <li>Введение процедуры реализации имущества утверждением финансового управляющего;</li>
                        <li>Передача исполнительных производств арбитражному управляющему (закрытие);</li>
                        <li>Прекращение звонков из банков;</li>
                        <li>Публикация в газете "Комерсант" и ЕФРСБ о начале процедуры банкротства;</li>
                        <li>Проведение собраний кредиторов.</li>
                    </ul>
                </div>
                <div className={[
                    styles.stage,
                    activeStage === 4 ? styles.active : ''
                ].join(' ')}>
                    <h3 onClick={() => setActiveStage(4)}>Этап 4</h3>
                    <ul>
                        <li>Вынесение определения суда о завершении процедуры;</li>
                        <li>Списание всех долгов.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}