import styles from './Header.module.css';
import {useContext, useEffect, useState} from "react";
import {SwiperContext} from "../../contexts/SwiperContext/SwiperContext";
import {SwiperClass} from "swiper/react";
import {useLocation, useNavigate} from "react-router-dom";

export default function Header() {
    const { swiperInstance, swiperActiveIndex } = useContext(SwiperContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location && swiperInstance) {
            let mainPath = location.pathname.split('/')[1];
            console.log('Main path: ' + mainPath);
            switch(mainPath){
                case 'invite':
                    (swiperInstance as SwiperClass)?.slideTo(0, 100)
                    break;
                case 'about':
                    (swiperInstance as SwiperClass)?.slideTo(1, 100)
                    break;
                case 'procedure':
                    (swiperInstance as SwiperClass)?.slideTo(2, 100)
                    break;
                case 'contacts':
                    (swiperInstance as SwiperClass)?.slideTo(3, 100)
                    break;
                default:
                    navigate('/invite');
                    break;
            }
        }
    }, [location.pathname, swiperInstance])

    const [menuOnDisplay, setMenuOnDisplay] = useState(false);

    return (
        <div className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.logo}>
                    <img src="/img/femida_header.png" alt="logo"/>
                </div>
                <nav className={[styles.menu, menuOnDisplay ? styles.display : ''].join(' ')}>
                    <p
                       className={swiperActiveIndex === 0 ? styles.selected : ''}
                       onClick={() => {
                           navigate('/invite');
                           setMenuOnDisplay(false);
                       }}>
                        Главная
                    </p>
                    <p
                       className={swiperActiveIndex === 1 ? styles.selected : ''}
                       onClick={() => {
                           navigate('/about');
                           setMenuOnDisplay(false);
                       }}>
                        О компании
                    </p>
                    <p
                       className={swiperActiveIndex === 2 ? styles.selected : ''}
                       onClick={() => {
                           navigate('/procedure');
                           setMenuOnDisplay(false);
                       }}>
                        Процедура
                    </p>
                    <p
                       className={swiperActiveIndex === 3 ? styles.selected : ''}
                       onClick={() => {
                           navigate('/contacts');
                           setMenuOnDisplay(false);
                       }}>
                        Контакты
                    </p>
                    <a
                        className={styles.feedback}
                        href={'https://yandex.ru/maps/org/femida/150933202060/reviews/?ll=38.921693%2C47.217598&utm_content=more-reviews&utm_medium=reviews&utm_source=maps-reviews-widget&z=17'}
                        target={'_blank'}
                        >
                        Отзывы
                    </a>
                </nav>
                <div className={[styles.burger, menuOnDisplay ? styles.active : ''].join(' ')} onClick={() => {
                    setMenuOnDisplay(!menuOnDisplay);
                }}>
                    <div className={[styles.burger__button, menuOnDisplay ? styles.active : ''].join(' ')}></div>
                </div>
            </div>
        </div>
    )
}