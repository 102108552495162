import styles from './AboutPage.module.css'
import {Callback} from "../../callback/Callback";

export default function AboutPage() {
    return (
        <div id={'about-page'} className={[styles.main].join(' ')}>
            <h2>О компании</h2>
            <div className={styles.block}>
                <div className={styles.benefits}>
                    <h3>Ваша выгода</h3>
                    <ul>
                        <li>Вы платите только после ПОЛОЖИТЕЛЬНОГО результата;</li>
                        <li>Наши гарантии закреплены в договоре;</li>
                        <li>Защитим и сохраним Ваше имущество;</li>
                        <li>Предложим 2-3 варианта решения Вашей ситуации;</li>
                        <li>Работаем "под ключ", от сбора документов до решения суда.</li>
                    </ul>
                </div>
                <div className={styles.form}>
                    <div className={styles.formBlock}>
                        <Callback title={'Закажите бесплатную консультацию!'}/>
                    </div>
                </div>
            </div>
            <div className={styles.info}>
                <p>Областной юридический центр "Фемида" предоставляет услуги по законному списанию долгов, урегулированию проблем с кредитами, проведению процедуры банкротства физических лиц.</p>
                <p>Наши специалисты избавят Вас от необходимости проходить все круги бюрократии и изучать юридические тонкости.</p>
                <p>Мы вернем Вам спокойную жизнь.</p>
            </div>
        </div>
    )
}