import styles from './InvitePage.module.css'
import {useNavigate} from "react-router-dom";
import ym from "react-yandex-metrika";
import React from "react";

export default function InvitePage() {
    const navigate = useNavigate();
    return (
        <div id={'invite-page'} className={[styles.main].join(' ')}>
            <h1 className={styles.slogan}>Законно освободим от кредитов и займов!</h1>
            <div className={styles.block}>
                <div className={styles.info}>
                    <div className={styles.phones}>
                        <a href={'tel:+79185884524'}>+7 (918) 588-45-24</a>
                        <a href={'tel:+79612828386'}>+7 (961) 282-83-86</a>
                        <a href={'tel:+79524171771'}>+7 (952) 417-17-71</a>
                    </div>
                    <div className={styles.address}>
                        347900, Ростовская область <br />
                        г.Таганрог, ул. Фрунзе 65
                    </div>
                    <div className={styles.socialIcons}>
                        <a href={'whatsapp://send?phone=79185884524'} target={'_blank'}><img src="/img/whatsapp.svg" alt="whatsapp"/></a>
                        <a href={'tg://resolve?domain=femidaTag174&text=Здравствуйте.'} target={'_blank'}><img src="/img/telegram.svg" alt="telegram"/></a>
                        <a href={'https://vk.com/club194519124'} target={'_blank'}><img src="/img/vk.png" alt="vk"/></a>
                    </div>
                </div>
                <div className={styles.jurist}>
                    <img src="/img/jurist.png" alt="jurist"/>
                </div>
            </div>
            <div className={styles.callback}>
                <h2>Запишитесь на бесплатную консультацию.</h2>
                <input type={'button'}
                       value={'Оставить заявку'}
                       onClick={() => {
                           ym('reachGoal', 'ModalFormOpened');

                           navigate('?modal');
                }}/>
            </div>
        </div>
    )
}