import styles from './Content.module.css';
import InvitePage from "./invite/InvitePage";
import AboutPage from "./about/AboutPage";
import ProcedurePage from "./procedure/ProcedurePage";
import ContactsPage from "./contacts/ContactsPage";
import {Swiper, SwiperClass, SwiperSlide} from "swiper/react";
import {Mousewheel, Keyboard, FreeMode} from "swiper/modules";
import {useContext} from "react";
import {SwiperContext} from "../../contexts/SwiperContext/SwiperContext";
import {useLocation, useNavigate} from "react-router-dom";
import ym from "react-yandex-metrika";

export default function Content() {
    const { setSwiperInstance, setSwiperActiveIndex } = useContext(SwiperContext);
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Swiper
            className={styles.main}
            wrapperClass={styles.wrapper}
            modules={[Mousewheel, Keyboard, FreeMode]}
            direction={'vertical'}
            mousewheel
            keyboard
            slidesPerView={"auto"}
            onSwiper={(swiper: SwiperClass) => {setSwiperInstance ? setSwiperInstance(swiper) : console.log("Swiper was not be instantiated!")}}
            onSlideChange={(swiper: SwiperClass) => {
                setSwiperActiveIndex ? setSwiperActiveIndex(swiper.activeIndex) : console.log("SwiperIndex was not be instantiated!");
                switch (swiper?.activeIndex ?? 0){
                    case 0:
                        ym('hit', '#/invite');
                        navigate('/invite' + location.search);
                        break;
                    case 1:
                        ym('hit', '#/about');
                        navigate('/about' + location.search);
                        break;
                    case 2:
                        ym('hit', '#/about');
                        navigate('/procedure' + location.search);
                        break;
                    case 3:
                        ym('hit', '#/contacts');
                        navigate('/contacts' + location.search);
                        break;
                }
                console.log("Slide changed to " + swiper.activeIndex)
            }}
        >
            <SwiperSlide className={styles.page}><InvitePage /></SwiperSlide>
            <SwiperSlide className={styles.page}><AboutPage /></SwiperSlide>
            <SwiperSlide className={styles.page}><ProcedurePage /></SwiperSlide>
            <SwiperSlide className={styles.page}><ContactsPage /></SwiperSlide>
        </Swiper>
    )
}