import styles from './ContactsPage.module.css'

export default function ContactsPage() {
    return (
        <div id={'contacts-page'} className={[styles.main].join(' ')}>
            <h2>Контакты</h2>
            <div className={styles.contacts}>
                <div className={styles.phones}>
                    <div className={styles.leftAlign}>Телефоны:</div>
                    <div className={styles.rightAlign}>
                        <a href="tel:+79185884524">+7 (918) 588-45-24</a>
                    </div>
                    <div></div>
                    <div  className={styles.rightAlign}>
                        <a href="tel:+79612828386">+7 (961) 282-83-86</a>
                    </div>
                    <div></div>
                    <div  className={styles.rightAlign}>
                        <a href="tel:+79524171771">+7 (952) 417-17-71</a>
                    </div>
                </div>
                <div className={styles.addresses}>
                    <div className={styles.leftAlign}>E-mail:</div>
                    <div  className={styles.rightAlign}>
                        <a href="mailto:femida-taganrog@inbox.ru">femida-taganrog@inbox.ru</a>
                    </div>
                    <div className={styles.leftAlign}>Адрес:</div>
                    <div  className={styles.rightAlign}>347900, Ростовская область</div>
                    <div></div>
                    <div  className={styles.rightAlign}>г.Таганрог, ул. Фрунзе, 65</div>
                </div>
            </div>
            <div className={styles.panorama}>
                <iframe
                    src="https://yandex.ru/map-widget/v1/?l=stv%2Csta&ll=38.922299%2C47.217594&mode=search&oid=150933202060&ol=biz&panorama%5Bdirection%5D=220.061052%2C0.108841&panorama%5Bfull%5D=true&panorama%5Bpoint%5D=38.921808%2C47.217691&panorama%5Bspan%5D=117.766470%2C60.000000&sctx=ZAAAAAgCEAAaKAoSCWn8witJBkhAEdL%2Fci1aMEdAEhIJSMFTyJV6Vj8Rb%2FHwngPLQT8iBgABAgMEBSgKOABAJUgBYjNyZWFycj1zY2hlbWVfTG9jYWwvR2VvL0NvbGxlY3Rpb25zL0VuYWJsZWRNaXg9ZmFsc2VqAnJ1nQHNzEw9oAEAqAEAvQEdxEhPwgELjMnHorIE3cbL6gPqAQDyAQD4AQCCAh3RhNC10LzQuNC00LAg0YLQsNCz0LDQvdGA0L7Qs4oCAJICAzk3MZoCDGRlc2t0b3AtbWFwcw%3D%3D&sll=38.922299%2C47.217594&sspn=0.002725%2C0.001061&tab=panorama&text=%D1%84%D0%B5%D0%BC%D0%B8%D0%B4%D0%B0%20%D1%82%D0%B0%D0%B3%D0%B0%D0%BD%D1%80%D0%BE%D0%B3&utm_source=share&z=19.35"
                    width="100%"
                    height="100%"
                    allowFullScreen={true}
                    style={{position: 'relative'}}>
                </iframe>
            </div>
        </div>
    )
}