import React, {createContext, useState, PropsWithChildren} from "react";
import {ISwiperContext} from "./ISwiperContext";
import {SwiperClass} from "swiper/react";

export const SwiperContext = createContext<Partial<ISwiperContext>>({})

export default ( { children } : PropsWithChildren ) => {
    const [swiperInstance, setSwiperInstance] = useState<SwiperClass>();
    const [swiperActiveIndex, setSwiperActiveIndex] = useState<number>(0)

    return (
        <SwiperContext.Provider value={{swiperInstance, setSwiperInstance, swiperActiveIndex, setSwiperActiveIndex}}>
            {children}
        </SwiperContext.Provider>
    )
}